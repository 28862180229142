html, body {
  height: 100%;
  margin: 0;
  background-color: #aee1fe;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  width: 70%;
  margin: 0 auto; /* Center the app container */
}

.quiz-container {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.horizontal-container {
  text-align: center;
}

.quote {
  font-size: 1.5em;
  margin-bottom: 20px;
  font-family: 'Courier Prime';
}

/* .score {
  font-size: 2.0em;
} */

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.option-button {
  color: white;
  background-color: #6c757d;
  border: none;
  border-radius: 5px;
  width: 500px;
  max-width: 500px;
  margin: 10px;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center text horizontally */
  text-align: center; /* Center text inside each line */
}

.option-button .title {
  font-size: 1.2em; /* Larger font size for the title */
  font-weight: bold; /* Bold font for the title */
}

.option-button .author {
  font-size: 0.9em; /* Smaller font size for the authors */
  margin-top: 5px; /* Add some space between title and authors */
}

.option-button.correct {
  background-color: #4caf50;
}

.option-button.incorrect {
  background-color: #f44336;
}

.option-button.highlight-correct {
  background-color: #4caf50;
}

.result {
  margin-top: 20px;
  font-size: 1.5em;
}

.correct-animation {
  animation: correct-animation 1s forwards;
}

.incorrect-animation {
  animation: incorrect-animation 1s forwards;
}

@keyframes correct-animation {
  0% { color: #4caf50; transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

@keyframes incorrect-animation {
  0% { color: #f44336; transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: 600px) {
  .App {
    width: 90%;
  }

  .option-button {
    width: 300px;
    max-width: 300px;
  }
}

/* Toggle Switch Styles */
/* .toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 10px auto;
  padding: 10px;
} */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #47b9f9;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.toggle-label {
  color: #333;
  font-size: 1em;
}

.header-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;  /* Three columns: empty | score | toggle */
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  justify-self: end;  /* Align to the right of its grid cell */
}

.link-container {
  display: flex;
  align-items: center;
  justify-self: start;  /* Align to the right of its grid cell */
}

.score {
  text-align: center;
  font-size: 2.0em;
  color: #333;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .toggle-container {
    flex-direction: column;
    align-items: center;
    gap: 5px;
    order: -1;  /* This makes it appear first in the flex container */
  }

  .toggle-label {
    font-size: 0.9em;
  }

  .score {
    text-align: center;
  }
}
